import { useEffect, useState } from 'react';
import DonutChart from './DonutChart';
import axios from 'axios';
import { hostName } from '../../App';
import useIsEmployee from '../../utils/Locations/useIsEmployee';
import { useParams } from 'react-router-dom';

function EmployeeAnalytics() {
  const [chartData, setChartData] = useState();
  const isEmployee = useIsEmployee();
  const { id } = useParams();

  let getStrategy = async () => {
    const user = JSON.parse(sessionStorage.getItem('dasid'));
    const API = `${hostName}/api/CountsOfProjects/${isEmployee ? id : user}`;
    console.log('STATS', API, id, isEmployee, user);

    await axios
      .get(API)
      .then(response => {
        console.log('ALL TASKS ANALYTICS', response.data);
        setChartData(response.data.percentage);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const convertedData = [
    { value: chartData?.completed_tasks || 20, name: 'Completed Tasks' },
    { value: chartData?.completed_on_time, name: 'Completed On Time' },
    { value: chartData?.completed_after_time, name: 'Completed After Time' },
    { value: chartData?.over_due - 20, name: 'Overdue' },
    { value: chartData?.pending_tasks, name: 'Pending Tasks' },
  ];
  const option = {
    tooltip: {
      trigger: 'item',
    },
    color: ['#00D9C0', '#00ff00', '#fbff00', '#D80032', '#ff6f00'],
    legend: {
      show: false,
      top: '5%',
      left: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: ['60%', '95%'],
        avoidLabelOverlap: false,
        padAngle: 2,
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: false,
          position: 'center',
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 20,
        //     fontWeight: 'bold',
        //     z: 5,
        //   },
        // },
        labelLine: {
          show: false,
        },
        data: convertedData,
      },
    ],
  };

  useEffect(() => {
    getStrategy();
  }, []);

  return (
    <div className="py-2 ">
      <DonutChart option={option} />
    </div>
  );
}

export default EmployeeAnalytics;

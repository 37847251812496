import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hostName } from '../../App';
import { Store } from '../../Context/StateStore';
import ViewTaskData from './ViewTaskData';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../api/AxiosClient';

const OverdueRight = ({ data, type }) => {
  let navigate = useNavigate();
  let [taskid, setTaskId] = useState();
  let [subTask, setSubTask] = useState();
  let { getNotification } = useContext(Store);
  const [loading, setloading] = useState(false);
  const [declineId, setDeclineId] = useState();
  const [declineMessage, setDeclineMessage] = useState();

  const FetchApprovals = async () => {
    const response = await axiosInstance.get(
      `/api2/ProjectCreationByEmployee/MTM24EMP1E/`,
    );

    return response.data;
  };

  const {
    data: approvalsData,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['AllApprovals'],
    queryFn: FetchApprovals,
  });

  approvalsData && console.log('APPROVALS', approvalsData);

  let removeNotification = id => {
    setloading(true);
    axios
      .post(
        `${hostName}/api/DeleteNotifications/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        id,
      )
      .then(response => {
        console.log(response.data);
        getNotification();
        setloading(false);
      })
      .catch(error => {
        console.log(error);
        setloading(false);
      });
  };
  let handleApprove = (nid, id, status, type) => {
    let obj1 = {
      pid: id,
      status: status,
      reason: declineMessage,
    };
    let obj2 = {
      tid: id,
      status: status,
      reason: declineMessage,
    };
    console.log(type === 'task' ? obj2 : obj1);
    setloading(true);
    axios
      .put(
        `${hostName}/api/UpdateTaskStatusBySuperior/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        type === 'task' ? obj2 : obj1,
      )
      .then(response => {
        console.log(response.data);
        window.location.reload();
        setDeclineId('');
        setDeclineMessage('');
        getNotification();
        setloading(false);
        removeNotification([nid]);
      })
      .catch(error => {
        console.log(error);
        setloading(false);
      });
  };
  const [activeTab, setActiveTab] = useState('projects');

  return (
    <div>
      <div className="flex flex-col items-center justify-center p-4">
        <div className="flex space-x-2 bg-gray-200 p-1 rounded-lg">
          <button
            className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
              activeTab === 'projects'
                ? 'bg-blue-500 text-white'
                : 'bg-white text-black'
            }`}
            onClick={() => {
              setActiveTab('projects');
            }}
          >
            Projects
          </button>
          <button
            className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
              activeTab === 'tasks'
                ? 'bg-blue-500 text-white'
                : 'bg-white text-black'
            }`}
            onClick={() => setActiveTab('tasks')}
          >
            Tasks
          </button>
        </div>
      </div>
      <div className="h-[80vh] px-2 scrollbar2 overflow-y-scroll ">
        {approvalsData?.[activeTab]?.reverse()?.map(x => {
          console.log(x, 'NOT TYPE');
          return (
            <div className="py-2 p-2 border-y-[1px] ">
              <p className=" text-slate-900 fw-medium ">
                {' '}
                Task Name :
                <span className=" text-slate-500"> {x.task_name}</span>{' '}
              </p>
              <p className=" text-slate-900 fw-medium ">
                {' '}
                Assigned to :
                <span className=" text-slate-500">
                  {' '}
                  {x.assigned_to ? x.assigned_to.name : 'Not assigned'}
                </span>{' '}
              </p>
              <div className="flex items-center justify-between">
                <p className=" text-slate-900 fw-medium mb-0">
                  {' '}
                  Deadline :
                  <span className=" text-slate-500">
                    {' '}
                    {x.due_date.slice(0, 10)}{' '}
                  </span>
                </p>
                {x.assigned_to && (
                  <button
                    onClick={() =>
                      navigate(`/dashboard/chat/${x.assigned_to.employee_id}`)
                    }
                    className="p-1 px-3 bg-blue-500 rounded text-white "
                  >
                    chat
                  </button>
                )}
                <button
                  onClick={() => {
                    setTaskId(x.id);
                  }}
                  className="bg-blue-400 p-2 rounded text-white text-xs"
                >
                  vieww
                </button>
              </div>
            </div>
          );
        })}
        {/* {type &&
          data &&
          [...data]
            .reverse()
            .filter((x, index) => x.tipe)
            .map((x, index) => {
              console.log(x, 'TYPE');

              return (
                <div
                  key={index}
                  className=" border-1 p-2 pt-3 rounded m-2 bg-slate-50 "
                >
                  {x.message}
                  {x.tipe && (
                    <>
                      <div className="flex justify-end my-2">
                        <button
                          onClick={() => {
                            if (x.project) {
                              handleApprove(x.id, x.project, 'True', 'project');
                            } else {
                              handleApprove(x.id, x.task, 'True', 'task');
                            }
                          }}
                          className="bg-green-400 p-2 rounded text-white text-xs "
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => {
                            if (declineId !== x.id) {
                              setDeclineId(x.id);
                            } else {
                              setDeclineId('');
                            }
                          }}
                          className="bg-red-400 p-2 rounded text-white text-xs mx-2"
                        >
                          {' '}
                          Decline{' '}
                        </button>
                        <button
                          onClick={() => {
                            setTaskId(x.project);
                            setSubTask(x.task);
                          }}
                          className="bg-blue-400 p-2 rounded text-white text-xs"
                        >
                          View
                        </button>
                      </div>
                      {declineId === x.id && (
                        <div className="flex items-center gap-3">
                          <textarea
                            value={declineMessage}
                            onChange={e => setDeclineMessage(e.target.value)}
                            name=""
                            className="outline-none  p-2"
                            id=""
                            cols="30"
                            rows="3"
                          ></textarea>
                          <button
                            onClick={() => {
                              if (x.project) {
                                handleApprove(
                                  x.id,
                                  x.project,
                                  'False',
                                  'project',
                                );
                              } else {
                                handleApprove(x.id, x.task, 'False', 'task');
                              }
                            }}
                            className=" p-2 px-3 bg-blue-600 rounded text-white "
                          >
                            {loading ? 'Loading...' : 'Send'}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })} */}
        {(taskid || subTask) && (
          <ViewTaskData
            show={taskid}
            subTask={subTask}
            setSubTask={setSubTask}
            setshow={setTaskId}
          />
        )}
      </div>
    </div>
  );
};

export default OverdueRight;

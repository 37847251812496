import React, { useContext, useEffect, useState } from 'react';
import { Offcanvas, Spinner } from 'react-bootstrap';
import SideBar from './SideBar';
import { Store } from '../Context/StateStore';
import { useNavigate, useParams } from 'react-router-dom';
import Notification from './Notification';
import axios from 'axios';
import { hostName } from '../App';
import NotifyMessage from './NotifyMessage';
import ViewTaskData from './AdminDas2/ViewTaskData';
import { IoMdNotificationsOutline } from 'react-icons/io';
import ProfileIcon from '../Assests/profilepic.webp';
import { MdMailOutline } from 'react-icons/md';
import { TbLogout } from 'react-icons/tb';
import LogOutSVG from '../Assets/SVG/LogOutSVG';
import { TfiPowerOff } from 'react-icons/tfi';
import { FaPowerOff } from 'react-icons/fa6';
import ChatSVG from '../Assets/SVG/ChatSVG';

const NavBarComponent = () => {
  let {
    show,
    setshow,
    apicall,
    user,
    notification,
    setnotification,
    getNotification,
  } = useContext(Store);
  let [taskid, setTaskId] = useState();
  let [subTask, setSubTask] = useState();
  const [showOffcanvasnotify, setShowOffcanvasnotify] = useState(false);

  const [loading, setloading] = useState(false);
  const [declineId, setDeclineId] = useState();
  const [declineMessage, setDeclineMessage] = useState();
  const handleClose = () => {
    setshow(false);
  };
  const [allId, setAllId] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    getNotification();
  }, []);

  let removeNotification = id => {
    setloading(true);
    axios
      .post(
        `${hostName}/api/DeleteNotifications/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        id,
      )
      .then(response => {
        console.log(response.data);
        getNotification();
        setloading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  let handleApprove = (nid, id, status, type) => {
    let obj1 = {
      pid: id,
      status: status,
      reason: declineMessage,
    };
    let obj2 = {
      tid: id,
      status: status,
      reason: declineMessage,
    };
    console.log(type === 'task' ? obj2 : obj1);
    setloading(true);
    axios
      .put(
        `${hostName}/api/UpdateTaskStatusBySuperior/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        type === 'task' ? obj2 : obj1,
      )
      .then(response => {
        console.log(response.data);
        setAllId(prev => [...prev, nid]);
        setDeclineId('');
        setDeclineMessage('');
        getNotification();
        setloading(false);
        removeNotification([nid]);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const params = useParams();
  const currentPage = params['*'];

  const pageName = {
    dasreport: 'Daily Activity System',
    chat: 'Chat',
    tracker: 'Tracker',
    tasklist: 'Task List',
    pendingTask: 'Pending Task',
    completedTask: 'Completed Task',
    completedAfterTime: 'Completed After Time',
    completedOntime: 'Completed On Time',
    overdue: 'Over Due Tasks',
    profile: 'Settings',
    department: 'Departments',
  };

  const logout = () => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('dasid');
    sessionStorage.removeItem('daspk');

    window.location.href = 'https://hrm.meridahr.com/';
    // window.location.reload();
  };

  return (
    <div className="opacity-100   backdrop-blur-xl  border-bottom border-slate-500 flex items-center justify-between  px-3">
      <div className=" mt-3">
        <p className="text-2xl font-bold ">
          {currentPage === '' ? 'Dashboard' : pageName[currentPage]}
        </p>
      </div>
      <div className="flex items-center   p-2 gap-x-3">
        <div className="flex items-center gap-x-3 pr-16">
          <button
            onClick={() => setShowOffcanvasnotify(true)}
            className=" relative "
          >
            {notification && notification.length > 0 && (
              <p className="absolute -top-3 badge text-center text-xs  left-3 rounded text-white m-0  ">
                {' '}
                {notification.length}{' '}
              </p>
            )}
            <IoMdNotificationsOutline className="text-3xl hover:bg-zinc-50  duration-300 rounded-full " />
          </button>
          <button
            className="hover:bg-zinc-50 p-2 duration-300 rounded-full"
            onClick={() => navigate('/dashboard/chat')}
          >
            <ChatSVG />
          </button>
          <button
            onClick={() => setshow(!show)}
            className="p-2 d-xl-none border-1 hover:bg-zinc-50 h-fit rounded m-0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </button>

          <button
            className="hover:bg-zinc-50 p-2 duration-300 rounded-full"
            onClick={() => logout()}
          >
            <FaPowerOff className="text-xl" />
          </button>
        </div>
        <div className="">
          <button
            onClick={() => navigate('/Dashboard/profile')}
            className="rounded-full w-max h-max  "
          >
            {user !== undefined ? (
              <div className="flex items-center gap-x-2 bg ">
                <p className="uppercase flex mt-3">{user?.full_name}</p>
                <img
                  className="w-10 h-10 object-cover bg-black rounded-full"
                  src={user?.EmployeeProfile || ProfileIcon}
                  alt="Profile"
                />
                {console.log('USER', user)}
              </div>
            ) : (
              <Spinner animation="border" />
            )}
          </button>

          {/* <div className="flex flex-col gap-y-2 absolute top-10 z-30">
            <button className="bg-white py-1 px-2 rounded-md">LOGOUT</button>
            <button className="bg-white py-1 px-2 rounded-md">Settings</button>
          </div> */}
        </div>
      </div>

      {show && (
        <Offcanvas
          style={{ backgroundColor: '#6264D5' }}
          show={show}
          className=""
          onHide={handleClose}
        >
          <Offcanvas.Header
            className="m-0"
            closeButton
          ></Offcanvas.Header>
          <Offcanvas.Body>
            <SideBar setshow={setshow} />
          </Offcanvas.Body>
        </Offcanvas>
      )}

      {showOffcanvasnotify && (
        <Offcanvas
          show={showOffcanvasnotify}
          onHide={() => {
            setShowOffcanvasnotify(false);
          }}
          className="transi py-0  p-0"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <div className=" flex justify-between w-[90%]">
              <div>Notification</div>
              <div className="bg-red-200">
                {notification && notification.length > 0 && (
                  <div style={{ backgroundColor: '#ffffff' }}>
                    <button
                      onClick={() => {
                        let messageArray = notification
                          .filter(x => x.tipe !== true)
                          .map(x => x.id);
                        removeNotification(messageArray);
                      }}
                      className="rounded-md w-fit px-1 mx-auto flex border-1 border-slate-950 "
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0 bg-white m-0">
            <div>
              <div>
                {notification &&
                  [...notification].reverse().map((x, index) => {
                    console.log(x);
                    return (
                      <div
                        key={index}
                        className=" border-1 p-2 pt-3 rounded m-2 bg-slate-50 "
                      >
                        {x.message}
                        {x.tipe === true && (
                          <>
                            <div className="flex justify-end my-2">
                              <button
                                onClick={() => {
                                  if (x.project) {
                                    handleApprove(
                                      x.id,
                                      x.project,
                                      'True',
                                      'project',
                                    );
                                  } else {
                                    handleApprove(x.id, x.task, 'True', 'task');
                                  }
                                }}
                                className="bg-green-400 p-2 rounded text-white text-sm "
                              >
                                Approve
                              </button>
                              <button
                                onClick={() => {
                                  if (declineId != x.id) {
                                    setDeclineId(x.id);
                                  } else {
                                    setDeclineId('');
                                  }
                                }}
                                className="bg-red-400 p-2  rounded text-white text-sm mx-2"
                              >
                                {' '}
                                Decline{' '}
                              </button>
                              <button
                                onClick={() => {
                                  setTaskId(x.project);
                                  setSubTask(x.task);
                                }}
                                className="bg-blue-400 p-2 rounded text-white text-xs"
                              >
                                View
                              </button>
                            </div>
                            {declineId == x.id && (
                              <div className="flex items-center gap-3">
                                <textarea
                                  value={declineMessage}
                                  onChange={e =>
                                    setDeclineMessage(e.target.value)
                                  }
                                  name=""
                                  className="outline-none  p-2"
                                  id=""
                                  cols="30"
                                  rows="3"
                                ></textarea>
                                <button
                                  onClick={() => {
                                    if (x.project) {
                                      handleApprove(
                                        x.id,
                                        x.project,
                                        'False',
                                        'project',
                                      );
                                    } else {
                                      handleApprove(
                                        x.id,
                                        x.task,
                                        'False',
                                        'task',
                                      );
                                    }
                                  }}
                                  className=" p-2 px-3 bg-blue-600 rounded text-white "
                                >
                                  {loading ? 'Loading...' : 'Send'}
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>

            <NotifyMessage />

            {(taskid || subTask) && (
              <ViewTaskData
                show={taskid}
                subTask={subTask}
                setSubTask={setSubTask}
                setshow={setTaskId}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
  );
};

export default NavBarComponent;

import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { hostName } from '../App';
import Loading from './Loading';
import { Store } from '../Context/StateStore';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ADminDas2 from './ADminDas2';

const AdminDash = () => {
  let [editingDep, setEditing] = useState();
  let { setactive, allDetails, apicall, getCurrentDate } = useContext(Store);

  let [task, settask] = useState({
    task_name: '',
    priority: null,
    remarks: '',
    due_date: '',
    created_by: JSON.parse(sessionStorage.getItem('dasid')),
  });
  const [editobj, setEditobj] = useState({
    id: null,
    name: null,
    type: null,
  });
  let [show, setshow] = useState(false);
  const handleChangedept = e => {
    let { name, value } = e.target;
    setEditobj(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  let {
    employeeUnderUser,
    department,
    position,
    setDepartments,
    fetchDepartments,
    employee,
  } = useContext(Store);
  let [showDepartment, setshowDepartment] = useState();
  let [changethings, setchangethings] = useState();
  let [obj, setobj] = useState({
    position: '',
    department_name: '',
  });
  let addDepartment = () => {
    console.log(obj);

    axios
      .post(`${hostName}/api/DisplayDepartments/`, {
        department_name: obj.department_name,
        position: obj.position,
      })
      .then(response => {
        console.log(response.data);
        fetchDepartments();
        setobj({
          position: '',
          department_name: '',
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  let handleChange = e => {
    setobj(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  let handleChange2 = e => {
    let { name, value } = e.target;
    if (name === 'priority' && Number(value) > 10) {
      value = 10;
    }
    if (name === 'priority' && Number(value) < 0) {
      value = '';
    }
    if (name === 'due_date' && value < getCurrentDate()) {
      value = getCurrentDate();
    }
    settask(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    employeeUnderUser();
    fetchDepartments();
  }, []);

  let handleUpdate = () => {
    if (editobj.type === 'dept') {
      axios
        .put(`${hostName}/api/DisplayDepartments/`, editobj)
        .then(response => {
          console.log(response.data);
          setEditing(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (editobj.type === 'posi') {
      axios
        .patch(`${hostName}/api/DisplayDepartments/`, editobj)
        .then(response => {
          console.log(response.data);
          setEditing(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
    fetchDepartments();
  };
  let submitTask = () => {
    axios
      .post(
        `${hostName}/api2/ProjectCreationByEmployee/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        task,
      )
      .then(response => {
        toast.success('Project Has been Created', {
          position: 'top-center',
          theme: 'colored',
          autoClose: 3000,
        });
        setshow(false);
        apicall();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div>
      {employee !== undefined ? (
        <>
          <div className="row ">
            <main className="">
              <ADminDas2 />
            </main>
            {/* <main className="col-md-4 min-h-[40vh]  ">
              <div className="bg-blue-300 rounded-lg h-full w-full">
                <AdminDas3 />
              </div>
            </main> */}
          </div>

          <div className="flex flex-wrap gap-4 my-5 justify-around   ">
            {/* User creation */}
            {showDepartment && (
              <Modal
                centered
                show={showDepartment}
                onHide={() => setshowDepartment(false)}
              >
                <Modal.Header closeButton>
                  Add the New{' '}
                  {changethings === 'department' ? 'Department' : 'Role'}
                </Modal.Header>
                <Modal.Body>
                  Existed{' '}
                  {changethings === 'department' ? 'Department' : 'Role'}
                  <div className={`flex flex-wrap justify-around `}>
                    {department !== undefined && changethings === 'department'
                      ? department.map(x => {
                          return (
                            <div
                              onClick={() => {
                                setEditing(true);
                                setEditobj({
                                  id: x.id,
                                  name: x.department_name,
                                  type: 'dept',
                                });
                                setshowDepartment(false);
                              }}
                              className="bg-slate-100 px-2 m-1 p-1 rounded-xl"
                            >
                              {x.department_name}
                            </div>
                          );
                        })
                      : position.map(x => {
                          return (
                            <div
                              onClick={() => {
                                setEditing(true);
                                setEditobj({
                                  id: x.id,
                                  name: x.position,
                                  type: 'posi',
                                });
                                setshowDepartment(false);
                              }}
                              className="bg-slate-100 px-2 m-1 p-1 rounded-xl"
                            >
                              {x.position}
                            </div>
                          );
                        })}
                  </div>
                  <hr />
                  <div
                    className={`my-2 text-center ${
                      changethings === 'department' ? '' : 'd-none'
                    } `}
                  >
                    Enter the Department name :
                    <input
                      type="text"
                      name="department_name"
                      value={obj.department_name}
                      className="border-1 mx-1 outline-none my-2 p-2"
                      placeholder="Technical Team"
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={`my-2 text-center ${
                      changethings === 'department' ? 'd-none' : ''
                    } `}
                  >
                    Enter the Role name :
                    <input
                      type="text"
                      name="position"
                      value={obj.position}
                      className="border-1 mx-1 outline-none my-2 p-2"
                      placeholder="UI/Ux developer"
                      onChange={handleChange}
                    />
                  </div>
                </Modal.Body>
                <button
                  onClick={addDepartment}
                  className="ms-auto p-2 px-3 bg-green-600 rounded text-white m-3 w-fit"
                >
                  Add{' '}
                  {changethings === 'department' ? 'Department' : 'Position'}
                </button>
              </Modal>
            )}
          </div>
        </>
      ) : (
        <Loading />
      )}
      {editingDep && (
        <Modal
          centered
          onHide={() => setEditing(false)}
          show={editingDep}
        >
          <Modal.Body>
            <div>
              Change the Name :
              <input
                type="text"
                value={editobj.name}
                name="name"
                onChange={handleChangedept}
                className="outline-none rounded p-2 px-3 border-1 "
              />
              <button
                onClick={handleUpdate}
                className="p-2 px-3 bg-slate-500 text-white "
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {show && (
        <Modal
          centered
          className=""
          show={show}
          onHide={() => setshow(false)}
        >
          <Modal.Header
            className="p-3 "
            closeButton
          >
            Adding the Task
          </Modal.Header>
          <Modal.Body className="p-3">
            <form
              action=""
              className="flex justify-between flex-wrap"
            >
              <div className="sm:w-[45%] ">
                Task Name : <span className="text-red-600">*</span>
                <input
                  type="text"
                  onChange={handleChange2}
                  name="task_name"
                  className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                  placeholder="Enter the task name "
                />
              </div>
              <div className="sm:w-[45%] ">
                Priority :
                <input
                  type="number"
                  value={task.priority}
                  onChange={handleChange2}
                  name="priority"
                  className=" border-1 p-2 block my-2 rounded w-32 nospin bg-slate-50 outline-none"
                  placeholder="Enter in 1- 10 "
                />
              </div>
              <div className="sm:w-[45%] ">
                Remarks :
                <textarea
                  type="text"
                  onChange={handleChange2}
                  name="remarks"
                  className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                  placeholder="Enter the remarks "
                />
              </div>
              <div className="sm:w-[45%] ">
                Due Date :
                <input
                  type="date"
                  value={task.due_date}
                  onChange={handleChange2}
                  name="due_date"
                  className=" border-1 p-2 block my-2 rounded bg-slate-50 outline-none"
                  placeholder="Enter the task name "
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={submitTask}
              className="p-2 px-3 bg-green-600 text-white rounded"
            >
              Create
            </button>
            <button
              onClick={() => setshow(false)}
              className="p-2 px-3 bg-red-600 text-white rounded"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AdminDash;

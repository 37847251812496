import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { BiCalendar } from 'react-icons/bi';
import axios from 'axios';
import { hostName } from '../../../App';
import useIsEmployee from '../../../utils/Locations/useIsEmployee';

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const Calendar = ({
  getDas,
  setCalenderDate,
  getBillingDetails,
  calenderDate,
  email,
  emailId,
  employID,
}) => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const empID = user.EmployeeId;

  const isEmployee = useIsEmployee();
  console.log('ISEMPLOYEE', email, isEmployee);

  const [currentDate, setCurrentDate] = useState(dayjs().tz('Asia/Kolkata')); // Ensure correct timezone
  const [days, setDays] = useState([]); // Days in the current month
  const [statuses, setStatuses] = useState({}); // Mock activity statuses

  const [statusMonth, setStatusMonth] = useState(calenderDate);

  useEffect(() => {
    const fetchStatuses = async () => {
      const API = `${hostName}/api/MonthlyActivitySheet/${
        isEmployee ? employID : empID
      }/${+statusMonth.slice(0, 4)}/${+statusMonth.slice(5, 7)}`;

      console.log(API);
      try {
        const response = await axios.get(API);
        setStatuses(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchStatuses();
  }, [statusMonth, empID]);

  useEffect(() => {
    const startOfMonth = currentDate.startOf('month');
    const daysArray = [];
    const startDayOfWeek = (startOfMonth.day() + 6) % 7; // Adjust to make Monday the first day

    // Add empty cells for the days before the first of the month
    for (let i = 0; i < startDayOfWeek; i++) {
      daysArray.push(null);
    }

    // Add all days of the current month
    for (let i = 1; i <= currentDate.daysInMonth(); i++) {
      daysArray.push(currentDate.date(i).format('YYYY-MM-DD'));
    }

    setDays(daysArray);
  }, [currentDate]);

  const handlePreviousMonth = () => {
    const prevMonth = currentDate.subtract(1, 'month');
    setCurrentDate(prevMonth);
    setStatusMonth(prevMonth.format('YYYY-MM-DD'));
  };

  const handleNextMonth = () => {
    const nextMonth = currentDate.add(1, 'month');
    setCurrentDate(nextMonth);
    setStatusMonth(nextMonth.format('YYYY-MM-DD'));
  };

  const getStatusClass = date => {
    if (!date) return 'bg-transparent'; // Empty cells
    if (dayjs(date).isSame(dayjs(), 'day')) return 'bg-blue-500 text-white';
    if (statuses[date] === true) return 'bg-green-500';
    if (statuses[date] === false) return 'bg-red-500';

    return 'bg-white';
  };

  const isSelected = date => {
    return date === calenderDate ? 'border-2 border-blue-500' : '';
  };

  const [open, setOpen] = useState();

  const cDate = new Date(calenderDate);
  const formatedCDate = cDate.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const [formatedDate, setFormatedDate] = useState(formatedCDate);

  return (
    <div className="flex flex-col gap-y-2 place-items-end relative">
      <div className="flex items-center gap-x-2 bg-white border w-max rounded-lg p-2 h-max">
        <div className="p-1 rounded-md bg-[#6B6DD8] h-max">
          <BiCalendar
            className="text-xl cursor-pointer text-white"
            onClick={() => setOpen(prev => !prev)}
          />
        </div>
        <div>{formatedDate}</div>
      </div>

      <div
        className={`absolute top-[50px] right-0 w-[350px] h-[415px] rounded-2xl z-10 ${
          open ? 'block' : 'hidden'
        }`}
      >
        {/* Header */}
        <div className="flex items-center justify-between bg-[#6B6DD8] p-4 rounded-t-2xl">
          <div className="flex items-center justify-between w-full">
            <div className="text-white font-semibold">
              {currentDate.format('MMMM YYYY')}
            </div>
            <div className="flex space-x-2">
              <button
                onClick={handlePreviousMonth}
                className="text-white px-2 py-1 bg-[#4A4CC4] rounded"
              >
                {'<'}
              </button>
              <button
                onClick={handleNextMonth}
                className="text-white px-2 py-1 bg-[#4A4CC4] rounded"
              >
                {'>'}
              </button>
            </div>
          </div>
        </div>

        {/* Calendar Body */}
        <div className="bg-white p-4 rounded-b-2xl h-[332px]">
          <div className="grid grid-cols-7 text-center text-[#333] text-xs font-semibold mb-2">
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
              <div key={day}>{day}</div>
            ))}
          </div>
          <div className="grid grid-cols-7 gap-1 text-center">
            {days.map((date, index) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const cDate = new Date(date);
                  const formatedCDate = cDate.toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  });
                  setFormatedDate(formatedCDate);
                  setCalenderDate(date);
                  setOpen(false);
                  getDas(date, isEmployee ? emailId : email);
                  getBillingDetails(date);
                }}
                key={index}
                className={`h-10 flex items-center justify-center rounded ${getStatusClass(
                  date,
                )} ${isSelected(date)}`}
              >
                <span className="text-[#333] w-[38px] h-[38px] flex items-center justify-center font-bold">
                  {date ? dayjs(date).date() : ''}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;

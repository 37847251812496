import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { hostName } from '../App';
import { Store } from '../Context/StateStore';
import Loading from '../Component/Loading';
import DepDas from '../Component/DepDas';
import PlusIcon from '../SVG/PlusIcon';
import { Modal } from 'react-bootstrap';
import RupeeIcon from '../SVG/RupeeIcon';
import Calendar from '../Component/Dashboard/CalenderTAS/CalenderTAS';
import NavBarComponent from '../Component/NavBarComponent';
import { useLocation, useParams } from 'react-router-dom';
import useIsEmployee from '../utils/Locations/useIsEmployee';
import { axiosInstance } from '../api/AxiosClient';

const Das = props => {
  const { emailId, empName, empDes, empdepartment, employID } = props;
  const {
    setactive,
    calenderDate,
    setCalenderDate,
    setUser,
    getDas,
    setdas,
    currentDate,
    das,
    apicall,
    user,
    getCurrentDate,
  } = useContext(Store);

  const { id } = useParams();

  const isEmployee = useIsEmployee();

  const [emailMessage, setEmailMessage] = useState(
    'You Have Not yet Filled the DAS. Please Fill it.',
  );

  const [otherDepartment, setOtherDepartment] = useState('');
  const [departments, setDepartments] = useState([]);
  const [billingModel, setBillingModel] = useState('');
  const [billing_obj, setbilling_obj] = useState({
    billing_projected: 0,
    billing_acheived: 0,
    collection_projected: 0,
    collection_acheived: 0,
  });
  const email = JSON.parse(sessionStorage.getItem('email'));

  console.log('TODAYYY', calenderDate);
  const fetchDepartments = () => {
    axios
      .get(`${hostName}/api/DisplayDepartments/`)
      .then(response => {
        setDepartments(response.data.Departments);
        const department = response.data.Departments.find(
          obj => obj.id === empdepartment,
        );
        if (department) setOtherDepartment(department.department_name);
      })
      .catch(error => console.error('Error fetching departments:', error));
  };

  const getBillingDetails = date => {
    console.log('BILLING', date);

    axios
      .put(`${hostName}/api/BillingView/${sessionStorage.getItem('dasid')}/`, {
        date: date,
      })
      .then(response => {
        const billingData =
          response.data.length > 0 ? response.data[0] : billing_obj;
        setbilling_obj(billingData);
      })
      .catch(error => console.error(error));
  };

  const billing = () => {
    axios
      .post(
        `${hostName}/api/BillingView/${sessionStorage.getItem('dasid')}/`,
        billing_obj,
      )
      .then(() => {
        getDas(calenderDate, email);
        alert('Changes Made');
        setBillingModel('');
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    if (empdepartment) fetchDepartments();
  }, [empdepartment]);

  useEffect(() => {
    setactive(emailId ? 'emp' : 'das');
    getDas(calenderDate, isEmployee ? emailId : email);
    getBillingDetails(calenderDate);
  }, []);

  const sendMail = () => {
    console.log({
      message: emailMessage,
      emp_id: id,
      date: calenderDate,
    });
    axiosInstance
      .post(`/api/PushDasReviewMail`, {
        message: emailMessage,
        emp_id: id,
        date: calenderDate,
      })
      .then(res => {
        alert('Mail Sent');
        setEmailMessage('You Have Not yet Filled the DAS. Please Fill it.');
        console.log(res);
      });
  };

  return (
    <div className="px-6  py-4 ">
      {user && das && currentDate ? (
        <>
          <Calendar
            setCalenderDate={setCalenderDate}
            getDas={getDas}
            getBillingDetails={getBillingDetails}
            calenderDate={calenderDate}
            email={email}
            emailId={emailId}
            employID={employID}
          />

          <div className="flex flex-col sm:flex-row gap-4 mt-6 justify-between bg-white p-4 rounded shadow-lg">
            <div>
              <p>
                <strong>Name:</strong>{' '}
                <span className="uppercase">{empName || user.full_name}</span>
              </p>
              <p>
                <strong>Role:</strong>{' '}
                <span className="uppercase">{empDes || user.Position}</span>
              </p>
              <p>
                <strong>Reporting Time:</strong> 9:30 - 6:30
              </p>
            </div>
            <div className="flex flex-col items- gap-2 border rounded-md p-2 w-full max-w-md">
              <input
                className="flex-1 outline-none bg-transparent text-gray-700 placeholder-gray-400"
                type="text"
                value={emailMessage}
                placeholder="Type your message..."
                onChange={e => {
                  setEmailMessage(e.target.value);
                }}
              />
              <button
                className="bg-purple-500 w-max text-white px-4 py-1 rounded-md hover:bg-purple-600 transition"
                onClick={() => {
                  sendMail();
                }}
              >
                Send
              </button>
            </div>
          </div>

          {user.status === 'manager' &&
            (user.Department === 'Sales' ||
              user.Department === 'Business Development') && (
              <main className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
                {[
                  'billing_projected',
                  'billing_acheived',
                  'collection_projected',
                  'collection_acheived',
                ].map(type => (
                  <article
                    key={type}
                    className="bg-white border-2 p-4 rounded-lg shadow-lg flex flex-col gap-2 items-center hover:scale-105 transition transform"
                  >
                    <button
                      onClick={() => setBillingModel(type)}
                      disabled={getCurrentDate() !== calenderDate || emailId}
                      className="rounded-full p-2 text-violet-600 bg-pink-100 hover:bg-pink-200 border-2 transition"
                    >
                      <PlusIcon size={20} />
                    </button>
                    <div className="text-center">
                      <p className="text-sm capitalize">
                        {type.replace(/_/g, ' ')}
                      </p>
                      <p className="text-xl font-bold flex items-center justify-center gap-1">
                        <RupeeIcon />
                        {billing_obj[type]}
                      </p>
                    </div>
                  </article>
                ))}
              </main>
            )}

          <div className="mt-10 mb-4">
            <DepDas
              emailId={emailId}
              updateObj={console.log}
              todayDate={currentDate}
              dasSheet={das}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}

      {billingModel && (
        <Modal
          show={billingModel !== ''}
          onHide={() => setBillingModel('')}
          centered
        >
          <Modal.Header closeButton>
            {billingModel.replace(/_/g, ' ').toUpperCase()}
          </Modal.Header>
          <Modal.Body>
            <input
              onKeyDown={e => e.key === 'Enter' && billing()}
              onChange={e =>
                setbilling_obj(prev => ({
                  ...prev,
                  [billingModel]: e.target.value,
                }))
              }
              type="number"
              name={billingModel}
              placeholder="Amount"
              className="w-full border-2 rounded p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-violet-600"
            />
            <button
              onClick={billing}
              className="px-6 py-2 bg-violet-600 text-white rounded hover:bg-violet-700 transition"
            >
              Add
            </button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Das;

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import Registration from './Pages/Registeration';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Pages/page.css';
import Dashboard from './Pages/Dashboard';
import Protect from './Component/Protect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'flatpickr/dist/flatpickr.min.css';
import Hrms from './Pages/Hrms';
import LoginProd from './Component/LoginProd/LoginProd';
import ENVTYPE from './api/config';

let hostName;
let hrapi;
let hrpage;

if (process.env.NODE_ENV === ENVTYPE) {
  hostName = 'http://192.168.18.53:7000';
  hrapi = 'https://hrmbackendapi.meridahr.com';
  hrpage = 'https://hrm.meridahr.com';
} else {
  hostName = 'https://dasbackendapi.meridahr.com';
  hrapi = 'https://hrmbackendapi.meridahr.com';
  hrpage = 'https://hrm.meridahr.com';
}

export { hostName, hrapi, hrpage };

// export const hrpage = 'http://192.168.18.22:3002';
// export const hostName=`https://interview.meridatechminds.com/`

function App() {
  // useEffect(()=>{
  //  window.addEventListener('beforeunload',sessionStorage.removeItem('user'))
  // },[])
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route
            path="/developer/login"
            element={<Login />}
            // element={<LoginProd />}
          />
          <Route
            path="/"
            // element={<Login />}
            element={<LoginProd />}
          />
          <Route
            path="/hrms"
            element={<Hrms />}
          />
          <Route
            path="/registeration"
            element={<Registration />}
          />
          <Route
            path="/dashboard/*"
            element={<Protect Child={Dashboard} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

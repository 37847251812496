import { ClockIcon } from '@mui/x-date-pickers';
import { useState } from 'react';
import { axiosInstance } from '../../api/AxiosClient';

function TASEdit({ obj, handleChange, emailId, getCurrentDate, calenderDate }) {
  const [remarks, setRemarks] = useState(obj.remarks2);
  const [title, setTitle] = useState(obj.action_changed || obj.action_planned);

  console.log('DATA INCOMING', obj);

  const changeRemarks = (id, remarks) => {
    console.log({
      id: id,
      remarks2: remarks,
    });

    axiosInstance
      .patch(`/api/DASView/${JSON.parse(sessionStorage.getItem('email'))}/`, {
        id: id,
        remarks2: remarks,
        action_planned: title,
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const changeTitle = (id, title) => {
    console.log({
      id: id,
      action_changed: title,
    });

    axiosInstance
      .patch(`/api/DASView/${JSON.parse(sessionStorage.getItem('email'))}/`, {
        ...obj,
        id: id,
        action_changed: title,
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className=" w-full h-fit  border-2 bg-white p-3 py-2 rounded-lg my-7">
      <div className="border-s-[3px] p-2 py-0 my-2 border-violet-600 ">
        <input
          type="text"
          className=" h-max mb-0 w-full"
          value={title}
          disabled={emailId}
          // disabled={emailId || getCurrentDate() !== calenderDate}
          onChange={e => {
            setTitle(e.target.value);
            handleChange(e, obj.id);
            changeTitle(obj.id, e.target.value);
          }}
        />
        <hr className="m-0 mb-2" />
        <textarea
          onChange={e => {
            setRemarks(e.target.value);
            handleChange(e, obj.id);
            changeRemarks(obj.id, e.target.value);
          }}
          value={remarks}
          disabled={emailId}
          // disabled={emailId || getCurrentDate() !== calenderDate}
          className="m-0 w-full outline-none text-xs "
        ></textarea>
      </div>
      <p className="text-sm flex items-center gap-2 mb-0">
        <ClockIcon fontSize="" /> {obj.assigned_time} -
        {obj.estimated_completed_time}
      </p>
    </div>
  );
}

export default TASEdit;

import { useContext, useEffect, useState } from 'react';
import { hostName } from '../App';
import axios from 'axios';
import ProjectList from './AdminDas2/ProjectList';
import Members from './AdminDas2/Members';
import PendingList from './AdminDas2/PendingList';
import StickyNotes1 from './StickyNotes1';
import EmployeeTasksAnalysis from './AdminDas2/Analytics/EmployeeTasksAnalysis';
import EmployeeAnalytics from './Analytics/EmployeeAnalytics';
import AdminDas3 from './AdminDas3';
import { Store } from '../Context/StateStore';
import DASCards from './DASCards';

const ADminDas2 = () => {
  let { notification } = useContext(Store);

  let [allEmpytask, setAllEmpTask] = useState();
  let [allEmpy, setAllEmpy] = useState();
  let [pendingTask, setPendingTask] = useState();
  let [overdueData, setOverDueData] = useState();

  let getallTask = () => {
    axios
      .get(`${hostName}/api/all_tasks/`)
      .then(response => {
        console.log('ADMIN ALL TASKS', response.data);
        setAllEmpTask(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  let getPendingProjects = () => {
    const PENDINGAPI = `${hostName}/api/CreatedProjectsView/${JSON.parse(
      sessionStorage.getItem('dasid'),
    )}/pending/`;
    axios
      .get(
        `${hostName}/api/CreatedProjectsView/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/pending/`,
      )
      .then(response => {
        console.log('ADMIN ALL PENDING TASK', PENDINGAPI, response.data);
        setPendingTask(response.data);
      })
      .catch(error => {
        console.log('ADMIN ALL PENDING TASK', error);
      });
  };

  let getAllEmpy = () => {
    axios
      .get(
        `${hostName}/api2/SendEmployee/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
      )
      .then(response => {
        console.log('ALL EMPLOYEE', response.data);
        setAllEmpy(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const completedTasks = allEmpytask?.filter(
    each =>
      each.status === 'completed_after_time' ||
      each.status === 'completed_on_time',
  );

  const completedTasksAfterTime = allEmpytask?.filter(
    each => each.status === 'completed_after_time',
  );

  const completedTasksOnTime = allEmpytask?.filter(
    each => each.status === 'completed_on_time',
  );

  const pendingTasks = allEmpytask?.filter(each => each.status === 'pending');

  const overDueTasks = allEmpytask?.filter(each => each.status === 'overdue');

  console.log('ADMIN ALL TASKS', pendingTasks, completedTasks);
  console.log('COMPLETED', completedTasks);

  useEffect(() => {
    getallTask();
    getAllEmpy();
    getPendingProjects();
    getOverdue();
  }, []);

  let getOverdue = () => {
    axios
      .get(
        `${hostName}/api/CreatedProjectsView/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/over_due/`,
      )
      .then(response => {
        setOverDueData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const [active, setActive] = useState('projects');
  const [status, setStatus] = useState();

  return (
    <div className="row">
      <main className="">
        <DASCards
          allEmpy={allEmpy}
          active={active}
          setActive={setActive}
          status={status}
          setStatus={setStatus}
        />
        <div className="grid grid-cols-10 gap-4 mt-4 mx-10  ">
          <div className="col-span-7 bg-white h-max rounded-3xl shadow-md">
            <StickyNotes1 />
          </div>

          <div className="col-span-3 flex flex-col gap-4 h-full">
            <div className="bg-white px-4 py-3 rounded-3xl shadow-md">
              <p className="text-lg font-semibold">Analytics</p>
              <EmployeeAnalytics />
            </div>
            <div className="bg-white px-4 py-[21px] rounded-3xl shadow-md">
              <EmployeeTasksAnalysis />
            </div>
          </div>
        </div>

        <div
          id="details"
          className="grid grid-cols-12 grid-rows-1 gap-x-2 mt-4 rounded-lg"
        >
          <section className="col-span-12 rounded-2xl dascolbg">
            {allEmpy &&
              (active === 'employee' || active === 'filledStatus') && (
                <Members
                  data={allEmpy}
                  status={status}
                />
              )}
            {allEmpytask && active === 'tasks' && allEmpytask && (
              <ProjectList data={allEmpytask} />
            )}

            {allEmpytask && active === 'Pending' && (
              <PendingList
                data={pendingTask}
                title={'Pending Tasks'}
                to={'/dashboard/pendingTask'}
              />
            )}
            {allEmpytask && active === 'Overdue' && (
              <PendingList
                data={overDueTasks}
                title={'Overdue Tasks'}
                to={'/dashboard/overdue'}
              />
            )}
            {allEmpytask && active === 'Ongoing' && (
              <PendingList
                data={[]}
                title={'Ongoing Tasks'}
                to={'/dashboard/ongoing'}
              />
            )}
            {allEmpytask && active === 'Completed' && (
              <PendingList
                data={completedTasks}
                title={'Completed Tasks'}
                to={'/dashboard/completedTask'}
              />
            )}
            {allEmpytask && active === 'Completed On Time' && (
              <PendingList
                data={completedTasksOnTime}
                title={'Completed On Time Tasks'}
                to={'/dashboard/completedOnTime'}
              />
            )}
            {allEmpytask && active === 'Completed After Time' && (
              <PendingList
                data={completedTasksAfterTime}
                title={'Completed After Tasks'}
                to={'/dashboard/completedAfterTime'}
              />
            )}
          </section>
        </div>

        {active === 'approvals' && (
          <main className="col-span-12 rounded-2xl dascolbg">
            <div className="rounded-lg h-full w-full p-2">
              <AdminDas3 />
            </div>
          </main>
        )}

        {/* <div className="mt-3">
          <AllProjects />
        </div> */}
      </main>
    </div>
  );
};

export default ADminDas2;

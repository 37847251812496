import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { hostName } from '../../../App';
import StickyCard from './StickyCards';
import { Store } from '../../../Context/StateStore';

function StickyNotes() {
  let { user } = useContext(Store);

  const [stickyNotes, setStickyNotes] = useState([]);

  const userID = user.pk;

  console.log(user);
  // Fetch notes from the server
  const getNotes = () => {
    axios
      .get(`${hostName}/api2/NotesViewSet/${userID}/`)
      .then(response => {
        console.log(response.data);
        setStickyNotes(response.data);
      })
      .catch(error => {
        console.error('Error fetching notes:', error);
      });
  };

  // Add a new note
  const addNote = () => {
    axios
      .post(`${hostName}/api2/NotesViewSet/${userID}/`, {
        note: '',
      })
      .then(response => {
        setStickyNotes(prevNotes => [...prevNotes, response.data]);
      })
      .catch(error => {
        console.error('Error adding note:', error);
      });
  };

  // Update an existing note
  const updateNote = (id, updatedNote) => {
    if (user) {
      axios
        .patch(`${hostName}/api2/NotesViewSet/${id}/`, { note: updatedNote })
        .then(() => {
          setStickyNotes(prevNotes =>
            prevNotes.map(note =>
              note.id === id ? { ...note, note: updatedNote } : note,
            ),
          );
        })
        .catch(error => {
          console.error('Error updating note:', error);
        });
    }
  };

  let handleChange = (value, id) => {
    // let { value } = e.target;
    let findIndex = stickyNotes.findIndex(obj => obj.id === id);
    let arry = [...stickyNotes];
    arry[findIndex].note = value;
    setStickyNotes(arry);

    axios
      .patch(`${hostName}/api2/NotesViewSet/${userID}/`, {
        id: id,
        note: value,
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  // Delete a note
  const deleteNote = id => {
    axios
      .delete(`${hostName}/api2/NotesViewSet/${id}/`)
      .then(() => {
        setStickyNotes(prevNotes => prevNotes.filter(note => note.id !== id));
      })
      .catch(error => {
        console.error('Error deleting note:', error);
      });
  };

  // Clear all notes
  const clearAllNotes = () => {
    axios
      .post(
        `${hostName}/api2/DeleteAllNotes/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/`,
        stickyNotes.map(note => note.id),
      )
      .then(() => {
        setStickyNotes([]);
      })
      .catch(error => {
        console.error('Error clearing all notes:', error);
      });
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <div className="w-full h-full">
      <div className="border w-max">
        <h1
          style={{ fontSize: '20px', lineHeight: 'normal' }}
          className="text-[#27104E] p-2 m-2"
        >
          Sticky Notes
        </h1>
        <button
          onClick={addNote}
          className="bg-green-500 text-white p-2 rounded-md m-2"
        >
          + Add Note
        </button>
        <button
          onClick={clearAllNotes}
          className="bg-red-500 text-white p-2 rounded-md m-2"
        >
          Clear All
        </button>
      </div>
      {/* <div className="grid grid-cols-3 gap-4 p-4">
        {stickyNotes.map(note => (
          <StickyCard
            key={note.id}
            id={note.id}
            note={note.note}
            // date={note?.date}
            // onUpdate={updateNote}
            onUpdate={handleChange}
            onDelete={deleteNote}
          />
        ))}
      </div> */}
    </div>
  );
}

export default StickyNotes;

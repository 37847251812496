import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Context/StateStore';
import { hostName } from '../App';
import axios from 'axios';
import Loading from '../Component/Loading';
import AdminDash from '../Component/AdminDash';
import HelloUser from '../Component/Dashboard/HelloUser/HelloUser';
import StickyNotes1 from '../Component/StickyNotes1';
import CalenderDAS from '../Component/Dashboard/Calender/CalenderDAS';
import EmployeeAnalytics from '../Component/Analytics/EmployeeAnalytics';

const DashBoardContent = () => {
  const { apicall, user, allDetails } = useContext(Store);
  const status = JSON.parse(sessionStorage.getItem('status'));
  const [data, setdata] = useState();
  const [selfPending, setSelfPending] = useState();
  const [selfOverdue, setSelfOverdue] = useState();
  const [activeState, setActiveState] = useState('pending');

  const fetchData = async () => {
    try {
      const dasid = JSON.parse(sessionStorage.getItem('dasid'));
      if (dasid) {
        const [pendingRes, overdueRes] = await Promise.all([
          axios.get(`${hostName}/api/PendingProjects/pending/${dasid}`),
          axios.get(`${hostName}/api/PendingProjects/over_due/${dasid}`),
        ]);
        setSelfPending(pendingRes.data);
        setSelfOverdue(overdueRes.data);
        if (activeState === 'pending') setdata(pendingRes.data);
        else setdata(overdueRes.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  console.log('PENDING', selfPending);

  useEffect(() => {
    apicall();
    fetchData();
    sessionStorage.setItem('selectedEmp', 'all');
  }, [activeState]);

  return (
    <div className="poppins p-3">
      {allDetails && user ? (
        status?.toLowerCase() === 'admin' ? (
          <AdminDash />
        ) : (
          <div className="grid grid-cols-12 gap-3">
            {/* Row 1 */}

            {/* Row 2 */}
            <div className="col-span-12 lg:col-span-7 lg:row-span-3 rounded-3xl shadow-md">
              <HelloUser
                pendingData={selfPending}
                overDueData={selfOverdue}
              />
            </div>
            <div className="col-span-12 lg:col-span-5 lg:row-span-6 rounded-t-3xl  ">
              <CalenderDAS />
            </div>

            {/* Row 3 */}
            <div className="col-span-12 lg:col-span-7 lg:row-span-9 row-start-5 bg-white rounded-3xl shadow-md overflow-hidden">
              <StickyNotes1 />
            </div>
            <div className="col-span-12 lg:col-span-5 lg:row-span-6 lg:row-start-7 lg:col-start-8  bg-white px-2 rounded-3xl shadow-md">
              <p className="text-2xl font-semibold py-3 mb-2">Analytics</p>
              <EmployeeAnalytics />
            </div>
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default DashBoardContent;

import axios from 'axios';
import ENVTYPE from './config';

let hostName;

if (process.env.NODE_ENV === ENVTYPE) {
  console.log('ENV', 'DEV', process.env.NODE_ENV);
  hostName = 'http://192.168.18.53:7000';
} else {
  console.log('ENV', 'PROD');
  hostName = 'https://dasbackendapi.meridahr.com';
}

export const axiosInstance = axios.create({
  baseURL: hostName,
});

axiosInstance.interceptors.request.use(config => {
  console.log(`URL:${config.url}`, `${hostName}${config.url}`);
  return config;
});

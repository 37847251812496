import DeleteSVG from '../../../Assets/SVG/DeleteSVG';
import StarSVG from '../../../Assets/SVG/StarSVG';

function StickyCards({ index, obj, handleChange, handleDelete }) {
  // Function to generate light colors
  const getLightColor = id => {
    const hue = (id * 37) % 360;
    return `hsl(${hue}, 90%, 85%)`;
  };

  const now = new Date();

  const today = now.toDateString();

  console.log('TOTO', today);

  return (
    <div
      className="w-[214px] border h-[214px] p-2 flex flex-col justify-between"
      style={{ backgroundColor: getLightColor(obj.id) }}
    >
      <div>
        {/* <div className="flex justify-end ">
          <button className="bg-black p-2 items-end w-max rounded-full">
            <StarSVG />
          </button>
        </div> */}
      </div>
      <div className="h-28 relative">
        <textarea
          type="text"
          key={index}
          value={obj.note}
          placeholder="Add the Notes"
          className="text-sm bg-transparent p-2 outline-none overflow- text-black w-full h-full"
          onChange={e => handleChange(e, obj.id)}
        />
      </div>

      <div className="flex justify-between items-center rounded-lg">
        <p className="my-2 text-xs">{obj.createdAt || today}</p>

        <button
          onClick={() => handleDelete(obj.id)}
          className="bg-red-500 text-white p-2 w-max rounded-full"
        >
          <DeleteSVG />
        </button>
      </div>
    </div>
  );
}

export default StickyCards;

import { ClockIcon } from '@mui/x-date-pickers';
import { FaArrowRight } from 'react-icons/fa6';

function TasDetails({ obj, change_status }) {
  return (
    <div className="w-full my-3 h-fit relative border-2 bg-white p-3 py-2 rounded-lg">
      <div className="border-s-[3px] p-2 py-0 my-2 border-violet-600">
        <h5 className="mb-2 text-xs text-wrap w-full break-words overflow-hidden">
          {obj.action_planned}
        </h5>
        <hr className="m-0 mb-2" />
        <p className="m-0 text-xs text-black w-full break-words overflow-hidden">
          {obj.remarks}
        </p>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
        <p className="text-sm flex items-center gap-2">
          <ClockIcon className="text-[#D1D1D1]" />
          {obj.assigned_time} - {obj.estimated_completed_time}
        </p>
        {obj.action_acheived !== 'completed' && (
          <button
            className="bg-green-500 px-3 py-1 rounded-md text-white text-md"
            onClick={() => change_status(obj.id, 'completed')}
          >
            <FaArrowRight />
          </button>
        )}
      </div>
    </div>
  );
}

export default TasDetails;
